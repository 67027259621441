import * as uuid from "uuid";

export type Question = {
  title: string;
  points: number;
  corrections: Correction[];
  subQuestions?: Question[];
}

export type Correction = {
  feedback: string | undefined;
  deduction: number;
}

function generateId(text: string) {
  const urlId = uuid.v5(window.location.href, uuid.v5.URL);
  return uuid.v5(text, urlId);
}

export function questionId(question: Question) {
  return generateId(question.title);
}

export function correctionId(correction: Correction, question: Question) {
  return generateId(correction.feedback + question.title);
}