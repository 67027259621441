import { Question, Correction } from "./types";
import { takeWhile } from "ramda";

export function parse(input: string): Question[] {
  try {
    const rawQuestions = splitByQuestion(input);
    const questions = rawQuestions.map(toQuestion);
    return questions;
  } catch {
    return [];
  }
}

/*
Each element in the top level array of the result is of the following structure:
[question string line, Correction #1 string line, Correction #2 string line, ...]
So for example:
  Question 1 (/3)
    - Correction #1 (-1)
    - Multiple
      line and words
      correction (-2)
Would return:
[
  "Question 1 (/3)",
  "Correction #1 (-1)",
  "Multiple\nline and words\ncorrection (-2)"
]
*/
function splitByQuestion(input: string): string[][] {
  let lines = input.split("\n").filter((line) => !line.match(/^\s*$/)); // remove empty lines
  const padding =
    lines.length > 0
      ? takeWhile((x) => x === " ", Array.from(lines[0])).length
      : 0;
  lines = lines.map((line) => line.replace(new RegExp(` {${padding}}`), ""));

  return lines.reduce((result: string[][], line) => {
    if (line.startsWith("- ")) {
      // New feedback line
      const lastQuestion = result[result.length - 1];
      lastQuestion.push(line.slice(2));
    } else if (line.startsWith("  ")) {
      // continuation of multi line feedback
      const lastQuestion = result[result.length - 1];
      let lastCorrection = lastQuestion[lastQuestion.length - 1];
      lastCorrection += "\n" + line.slice(2);
      lastQuestion[lastQuestion.length - 1] = lastCorrection;
    } else {
      // Question line probably
      result.push([line]);
    }
    return result;
  }, []);
}

const numberRegex = "\\d+([,.]\\d+)?";

function toQuestion(rawQuestion: string[]): Question {
  const [question, ...rawCorrections] = rawQuestion;
  const questionRegex = new RegExp("(.*?)( ?\\(\\/(" + numberRegex + ")\\))");
  // eslint-disable-next-line
  const [_1, title, _2, points] = question.match(questionRegex) || [];

  return {
    title,
    points: parseFloat(points.replace(/,/, ".")),
    corrections: rawCorrections.map(toCorrection),
  };
}

function toCorrection(rawCorrection: string): Correction {
  const correctionRegex = new RegExp(
    "((.|\\n)*?)( ?\\(-?(" + numberRegex + ")\\))"
  );
  // eslint-disable-next-line
  const [_1, feedback, _2, _3, deduction] =
    rawCorrection.match(correctionRegex) || [];

  return {
    feedback,
    deduction: parseFloat(deduction?.replace(/,/, ".")),
  };
}
