import { Question, Correction, correctionId } from "./types";

export function calculateQuestionScore(
  question: Question,
  corrections: Correction[]
): number {
  const score = corrections
    .map((x) => x.deduction)
    .reduce((total, deduction) => total - deduction, question.points);
  return Math.round(score * 100) / 100;
}

export function calculateTotalScore(
  questions: Question[],
  selectedCorrections: string[]
): number {
  return questions
    .map((question) => {
      const corrections = question.corrections.filter((x) =>
        selectedCorrections.includes(correctionId(x, question))
      );
      return calculateQuestionScore(question, corrections);
    })
    .reduce((total, score) => total + score, 0);
}

export function calculateMaxScore(questions: Question[]) {
  return questions.reduce((result, next) => result + next.points, 0);
}

export function round(x: number, precision: number = 0.1) {
  const precisionModifier = 1 / precision; // 10 by default
  return Math.round(x * precisionModifier) / precisionModifier;
}

export function rescale(score: number, max: number, rescaleRatio: number) {
  return [round(score * rescaleRatio), round(max * rescaleRatio)];
}

export function rescaleString(
  score: number,
  max: number,
  rescaleRatio: number
) {
  const [scaledScore, scaledMax] = rescale(score, max, rescaleRatio);
  return `${scaledScore}/${scaledMax}`;
}
