import * as React from "react";
import Output from "./Output";
import { calculateQuestionScore } from "../util/score";
import { Question, correctionId } from "../util/types";

type Props = { questions: Question[] };

type ReduceCorrectionsAction =
  | { action: "TOGGLE_SELECT"; id: string }
  | { action: "RESET_SELECTION" };

function reduceCorrections(
  state: string[],
  action: ReduceCorrectionsAction
): string[] {
  switch (action.action) {
    case "TOGGLE_SELECT":
      const deselect = state.includes(action.id);
      return deselect
        ? state.filter((x) => x !== action.id)
        : [...state, action.id];
    case "RESET_SELECTION":
      return [];
  }
}

export default function Questions({ questions }: Props) {
  const [selectedCorrections, dispatchSelectedCorrection] = React.useReducer<
    React.Reducer<string[], ReduceCorrectionsAction>
  >(reduceCorrections, []);

  return (
    <>
      {questions.map((question) => {
        const corrections = question.corrections.filter((x) =>
          selectedCorrections.includes(correctionId(x, question))
        );
        const score = calculateQuestionScore(question, corrections);
        return (
          <>
            <strong>
              Vraag {question.title} ({score}/{question.points})
            </strong>
            <ul>
              {question.corrections.map((correction) => {
                const id = correctionId(correction, question);
                return (
                  <li key={id}>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedCorrections.includes(id)}
                        onClick={() =>
                          dispatchSelectedCorrection({
                            action: "TOGGLE_SELECT",
                            id,
                          })
                        }
                      />
                      <span
                        dangerouslySetInnerHTML={{
                          __html: (correction.feedback || "").replace(
                            /\n/g,
                            "<br>"
                          ),
                        }}
                      />{" "}
                      (-{correction.deduction})
                    </label>
                  </li>
                );
              })}
            </ul>
          </>
        );
      })}
      <button
        onClick={() =>
          dispatchSelectedCorrection({
            action: "RESET_SELECTION",
          })
        }
      >
        Reset feedback
      </button>
      <Output questions={questions} selectedCorrections={selectedCorrections} />
    </>
  );
}
