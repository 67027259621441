import * as React from "react";
import {
  calculateQuestionScore,
  calculateTotalScore,
  calculateMaxScore,
  rescaleString,
  round,
} from "../util/score";
import { Question, correctionId } from "../util/types";

type Props = {
  questions: Question[];
  selectedCorrections: string[];
};

function generateOutput(
  questions: Question[],
  selectedCorrections: string[],
  recalculateTotalTo: number
): string {
  const score = calculateTotalScore(questions, selectedCorrections);
  const maxScore = calculateMaxScore(questions);
  const rescaleRatio = recalculateTotalTo / maxScore;

  const feedback = questions
    .map((question) => {
      const corrections = question.corrections.filter((x) =>
        selectedCorrections.includes(correctionId(x, question))
      );
      const score = calculateQuestionScore(question, corrections);

      if (corrections.length === 0) {
        return null;
      }

      let result = `${question.title} (${rescaleString(
        score,
        question.points,
        rescaleRatio
      )}):\n`;

      for (let correction of corrections) {
        result += `- ${correction.feedback} (-${round(
          correction.deduction * rescaleRatio
        )})\n`;
      }

      return result;
    })
    .filter((x) => x !== null)
    .join("\n");

  return `${feedback}\nTotaal: ${rescaleString(score, maxScore, rescaleRatio)}`;
}

export default function Output({ questions, selectedCorrections }: Props) {
  const [recalculateTotalTo, setRecalculateTotalTo] = React.useState<number>(
    calculateMaxScore(questions)
  );

  React.useEffect(() => {
    setRecalculateTotalTo(calculateMaxScore(questions));
  }, [questions]);

  return (
    <div>
      Recalculate to a maximum points of{" "}
      <input
        type="number"
        step="0.1"
        value={recalculateTotalTo}
        onChange={(e) => setRecalculateTotalTo(parseFloat(e.target.value))}
      />
      <textarea
        value={generateOutput(
          questions,
          selectedCorrections,
          recalculateTotalTo
        )}
        style={{ height: "20em" }}
      />
    </div>
  );
}
