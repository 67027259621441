import * as React from "react";
import Questions from "./Questions";
import "./App.style.css";
import { parse } from "../util/parser";

const data = ``;

export default function App() {
  const [input, setInput] = React.useState(data.trim());
  const questions = parse(input);

  return (
    <div className="App">
      <textarea
        placeholder="input"
        value={input}
        onChange={(e) => setInput(e.currentTarget.value)}
        style={{ height: "10em" }}
      />
      <Questions questions={questions} />
    </div>
  );
}
